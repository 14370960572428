import React, { forwardRef, useMemo } from "react"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import "../../assets/print/quotation-page.css"

import TableLong from "components/common/print/TableLong"
import approved from "assets/approved.jpg"

import HeaderPrint from "components/common/print/HeaderPrint"
import { numberFormat } from "utils/thousand-separator"
import cloneDeep from "lodash/cloneDeep"
import { MARGINS } from "./constants"
import {
  useAlignStyles,
  useTableStyles,
} from "components/bill-of-material/BOMItemPrintStyles"

// saanjaya

const useStyles = makeStyles({
  logo: {
    width: "12mm",
  },
  pageHeaderContent: {
    width: "180mm",
    display: "flex",
    alignItems: "center",
    fontWeight: "bolder",
    fontStyle: "italic",
  },
  tableTitle: {
    fontWeight: "bold",
    marginBottom: "2mm",
  },
  page: {
    fontSize: "10pt",
    lineHeight: 1.1,
    "& table": {
      textAlign: "left",
    },
    "& th, & td, & tr": {
      // Remove row space by default
      marginBottom: 0,
      paddingBottom: 0,
    },
    "& table.table-headleft th": {
      paddingRight: "5mm",
    },
    "& table.table-headleft td": {
      textAlign: "right",
    },
    "& #general td": {
      textAlign: "left",
    },
    "& .table-long": {
      "& thead > * > *": {
        borderTop: "0.3mm solid black",
        borderBottom: "0.3mm solid black",
        padding: "1.1mm 2.6mm",
      },
      "& tbody > * > *": {
        padding: "1.05mm 2.6mm",
      },
      "& tbody > *:last-child > *": {
        borderBottom: "0.3mm solid black",
      },
    },
    "& .table-handling": {
      paddingRight: "2.6mm",
      pageBreakInside: "avoid",
      marginBottom: "3mm",
    },
    "& .table-handling tbody": {
      pageBreakInside: "avoid",
    },
    "& .title": {
      marginTop: 0,
      fontSize: "inherit",
    },
  },
  totalCostsTable: {
    "& * > * > *": {
      padding: "1.1mm 2.6mm",
      textAlign: "right",
    },
    "& * > * > :nth-child(1)": {
      textAlign: "left",
    },
    "& tbody > *:last-child > *": {
      paddingBottom: "1.7mm",
    },
    "& tfoot > *:first-child > *": {
      borderTop: "0.3mm solid black",
      paddingTop: "1.7mm",
    },
  },
  marginTable: {
    "& * > * > *": {
      padding: "1.1mm 2.6mm",
      textAlign: "right",
    },
  },
  approvedBox: {
    border: "0.3mm solid black",
    height: "15.5mm",
  },
  noBorder: {
    border: "none",
    width: "100%",
    padding: 0,
    // margin: "-10mm",
  },
})

const num = (number) => parseFloat(number) || 0
const numFormat = (number) => numberFormat(number, 2)
const numFormatForLwt = (number) => numberFormat(number, 1)

const TableHandling = ({ itemName, subtotalCost, handling, className }) => {
  subtotalCost = num(subtotalCost)
  const handlingCost = (subtotalCost * num(handling)) / 100
  const totalCost = handlingCost + subtotalCost

  return (
    <table
      id="table-handling"
      className={clsx("table-headleft", "table-handling", className)}
    >
      <tbody>
        <tr>
          <th>Subtotal {itemName}</th>
          <td>{numFormat(subtotalCost)}</td>
        </tr>
        <tr>
          <th>Handling ({numFormat(handling)}%)</th>
          <td>{numFormat(handlingCost)}</td>
        </tr>
        <tr>
          <th>Total material</th>
          <td>{numFormat(totalCost)}</td>
        </tr>
      </tbody>
    </table>
  )
}

const MarginCell = ({ margin, totalCost }) => {
  return (
    <>
      <th>{margin}%</th>
      <td>{numFormat((1 + margin / 100) * totalCost)}</td>
    </>
  )
}

const EQPrintForm = forwardRef(
  (
    {
      className,
      data,
      customerData,
      companyInfo = {},
      rateInCurr,
      rateOutCurr,
      printTarget,
      margin,
      marginCurrencies = [],
      marginMarkiplier = null,
    },
    ref
  ) => {
    rateInCurr = rateInCurr ?? {}
    rateOutCurr = rateOutCurr ?? {}
    customerData = customerData ?? {}
    let companyInformation = {}

    if ("data" in companyInfo) {
      companyInformation = companyInfo.data
    }

    const classes = useStyles()
    const tableClasses = useTableStyles()
    const alignClasses = useAlignStyles()

    /* Compute material costs and display */

    const materialData = useMemo(() => {
      let items = data.materialCosts || []
      let totalCost = 0
      if (items.length < 1) return { items, totalCost }

      items = items
        .map((estItem, i) => {
          const materialProcessCost = estItem?.process?.reduce((a, b) => {
            return a + b.estimateProcess.cost * b.time
          }, 0)
          const dim = (estItem?.dimension || "").toLowerCase().split("x")
          let weight =
            (num(dim[0] || 1) *
              num(dim[1] || 1) *
              num(dim[2] || 1) *
              num(estItem.material.materialType.density)) /
            1e6
          let materialPrice = estItem.unitPrice || estItem.material.unitPrice
          if (printTarget === "customer") {
            materialPrice *= 1 + (marginMarkiplier ?? margin) / 100
          }
          const total = weight * num(estItem.quantity) * num(materialPrice)

          const arr = [
            i + 1,
            estItem.description,
            estItem.material.materialCode,
            estItem.material?.materialType?.name || "-",
            estItem.dimension,
            weight,
            estItem.quantity,
            estItem.uom?.name,
            materialPrice,
            total,
            materialProcessCost,
            materialProcessCost + total,
          ]

          if (printTarget === "production") {
            arr.splice(8, 4)
          }

          return arr
        })
        .filter((item) => item)
      if (items.length < 1) return { items, totalCost }

      const TOTAL_INDEX = items[0].length - 3
      totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

      const NUMBER_FIELDS = [5, 6, 8, 9, 10, 11]

      if (printTarget === "production") {
        NUMBER_FIELDS.splice(2, 4)
      }
      items.forEach((item) => {
        NUMBER_FIELDS.forEach((i) => {
          item[i] = numFormat(item[i])
        })
      })
      return {
        items,
        totalCost,
      }
    }, [data.materialCosts, printTarget])

    /* Compute process costs and display */

    const processData = useMemo(() => {
      let items = data.processCosts || []
      let totalCost = 0
      if (items.length < 1) return { items, totalCost }

      items = items
        .map((estItem, i) => {
          if (estItem.time <= 0) return null
          let cost = estItem.cost
          if (printTarget === "customer") {
            cost *= 1 + (marginMarkiplier ?? margin) / 100
          }
          let time = estItem.time

          if (printTarget === "production" && data.productionHourReducer) {
            time -= time * (data.productionHourReducer / 100)
            time = Math.floor(time)
          }

          const arr = [
            i + 1,
            estItem.process?.name,
            time,
            estItem.materialProcessTime,
            estItem.uom?.name,
            num(estItem.time) + num(estItem.materialProcessTime),
            cost,
            num(estItem.time + +estItem.materialProcessTime) * num(cost),
          ]

          if (printTarget === "production") {
            arr.splice(5, 3)
          }

          return arr
        })
        .filter((item) => item)
      if (items.length < 1) return { items, totalCost }

      const TOTAL_INDEX = items[0].length - 1
      totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

      const NUMBER_FIELDS = printTarget === "production" ? [] : [2, 5, 3, 6, 7]

      items.forEach((item) => {
        NUMBER_FIELDS.forEach((i) => {
          item[i] = numFormat(item[i])
        })
      })

      return { items, totalCost }
    }, [data.processCosts, printTarget])

    /* Compute additional costs and display */

    const additionalData = useMemo(() => {
      let items = cloneDeep(data.additionalCosts || [])
      let totalCost = 0
      if (items.length < 1) return { items, totalCost }

      items = items.map((item, i) => {
        let cost = item.cost
        if (printTarget === "customer") {
          cost *= 1 + (marginMarkiplier ?? margin) / 100
        }
        const arr = [
          i + 1,
          item.process,
          item.quantity,
          item?.uom?.name,
          cost,
          item.handling,
          num(cost) * num(item.quantity) * (1 + item.handling / 100 || 0),
        ]

        if (printTarget === "production") {
          arr.splice(4, 3)
        }
        return arr
      })

      const TOTAL_INDEX = items[0].length - 1
      totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

      const NUMBER_FIELDS = printTarget === "production" ? [] : [4, 5, 6]

      items.forEach((item) => {
        NUMBER_FIELDS.forEach((i) => {
          item[i] = numFormat(item[i])
        })
        if (printTarget !== "production") item[5] = `${item[5] || 0}%`
      })

      return { items, totalCost }
    }, [data.additionalCosts, printTarget])

    const outsourcingData = useMemo(() => {
      let items = cloneDeep(data.outsourcingCosts || [])
      let totalCost = 0
      if (items.length < 1) return { items, totalCost }

      items = items.map((item, i) => {
        let cost = item.cost
        if (printTarget === "customer") {
          cost *= 1 + (marginMarkiplier ?? margin) / 100
        }
        const arr = [
          i + 1,
          item.process,
          item.quantity,
          item?.uom?.name,
          cost,
          item.handling,
          num(cost) * num(item.quantity) * (1 + item.handling / 100 || 0),
        ]

        if (printTarget === "production") {
          arr.splice(4, 3)
        }
        return arr
      })

      const TOTAL_INDEX = items[0].length - 1
      totalCost = items.reduce((sum, item) => sum + item[TOTAL_INDEX], 0)

      const NUMBER_FIELDS = printTarget === "production" ? [] : [4, 5, 6]

      items.forEach((item) => {
        NUMBER_FIELDS.forEach((i) => {
          item[i] = numFormat(item[i])
        })
        if (printTarget !== "production") item[5] = `${item[5] || 0}%`
      })

      return { items, totalCost }
    }, [data.outsourcingCosts, printTarget])

    const MATERIAL_DATA = materialData.items

    const MATERIAL_COLUMNS = [
      "No",
      "Item Description",
      "Item No.",
      "Material Type",
      "Dimension",
      "Weight",
      "Qty",
      "Uom",
      "Unit Price",
      "Total",
      "Process",
      "Material + Process Cost",
    ]

    if (printTarget === "production") {
      MATERIAL_COLUMNS.splice(8, 2)
    }

    const alignRight = { style: { textAlign: "right" } }

    const MAT_COL_PROPS = [
      null,
      null,
      null,
      null,
      alignRight,
      alignRight,
      alignRight, // X, Y, Z
      alignRight,
      alignRight,
      alignRight, // weight, qty, unit price
      alignRight, // total
    ]

    const materialElement = (
      <div style={{ pageBreakInside: "avoid" }}>
        <p className={clsx(classes.tableTitle, "fontSize")}>Material Cost</p>
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "flex-end",
            pageBreakInside: "avoid",
          }}
        >
          <TableLong
            className="fontSize"
            data={MATERIAL_DATA}
            columns={MATERIAL_COLUMNS}
            columnProps={MAT_COL_PROPS}
            style={{ width: "100%" }}
          />
        </div>
        {printTarget !== "production" ? (
          <div
            style={{
              maxWidth: "150mm",
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-end",
              pageBreakInside: "avoid",
            }}
          >
            <TableHandling
              className="fontSize"
              itemName="material"
              handling={data.materialCostHandling}
              subtotalCost={materialData.totalCost}
            />
          </div>
        ) : null}
      </div>
    )

    // Display process

    const PROCESS_DATA = processData.items
    const PROCESS_COLUMNS = [
      "No",
      "Item Description",
      // "Hour",
      "Quantity",
      "Material Process",
      "Uom",
      "Sub Total (Process)",
      "Unit Price",
      "Total",
    ]

    const PROCESS_COL_PROPS = [
      null,
      null,
      alignRight,
      alignRight,
      alignRight,
      alignRight,
      alignRight, // qty, unit price, total
    ]

    const processElement = (
      <div style={{ pageBreakInside: "avoid" }}>
        <p className={clsx(classes.tableTitle, "fontSize")}>Process Cost</p>
        <div
          style={{
            // maxWidth: "150mm",
            display: "flex",
            flexFlow: "column",
            alignItems: "flex-end",
            pageBreakInside: "avoid",
          }}
          id="process-cost-table"
          className="fontSize"
        >
          <TableLong
            className="fontSize"
            data={PROCESS_DATA}
            columns={PROCESS_COLUMNS}
            columnProps={PROCESS_COL_PROPS}
            style={{ width: "100%" }}
          />
        </div>
        {printTarget !== "production" ? (
          <div
            style={{
              maxWidth: "150mm",
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-end",
              pageBreakInside: "avoid",
            }}
          >
            <TableHandling
              className="fontSize"
              itemName="process"
              handling={data.processCostHandling}
              subtotalCost={processData.totalCost}
            />
          </div>
        ) : null}
      </div>
    )

    const ADDITIONAL_DATA = additionalData.items
    const OUTSOURCING_DATA = outsourcingData.items
    const ADDITIONAL_COLUMNS = [
      "No",
      "Item Description",
      "Quantity",
      "Uom",
      "Cost",
      "%",
      "Total",
    ]

    const OUTSOURCING_COLUMNS = [
      "No",
      "Item Description",
      "Quantity",
      "Uom",
      "Cost",
      "%",
      "Total",
    ]

    if (printTarget === "production") {
      MATERIAL_COLUMNS.splice(8, 2)
      PROCESS_COLUMNS.splice(5, 3)
      ADDITIONAL_COLUMNS.splice(4, 3)
      OUTSOURCING_COLUMNS.splice(4, 3)
    }

    const currentCheck = (data?.estQuotApproval?.edges ?? []).filter(
      (edge) => edge.node.approvalType === "CHECKED"
    )[0]
    const currentApprove = (data?.estQuotApproval?.edges ?? []).filter(
      (edge) => edge.node.approvalType === "APPROVED"
    )[0]

    const approvedImage = (
      <img
        src={approved}
        alt="approved"
        style={{
          width: "75px",
          height: "auto",
          position: "relative",
          top: "-14px",
          left: "8px",
        }}
      />
    )
    const approvalTable = (
      <div style={{ marginBottom: "3mm", marginTop: "3mm" }}>
        <table className={clsx(tableClasses.approvalTable, "fontSize")}>
          <thead>
            <tr>
              <th>Prepared By</th>
              <th>Checked By</th>
              <th>Approved By</th>
            </tr>
          </thead>
          <tbody style={{ marginTop: "4em", marginBottom: "4em" }}>
            <tr>
              <td>
                <br />
                <br />
              </td>
              <td>
                {currentCheck?.node?.judgement === "ACCEPT" && approvedImage}
              </td>
              <td>
                {currentApprove?.node?.judgement === "ACCEPT" && approvedImage}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>{data.prepared?.name ?? ""}</th>
              <th>{data.checked?.name ?? ""}</th>
              <th>{data.approved?.name ?? ""}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    )

    const ADDITIONAL_COL_PROPS = [
      null,
      null,
      alignRight,
      alignRight,
      alignRight, // qty, unit price, total
    ]

    const additionalElement = (
      <div style={{ pageBreakInside: "avoid" }}>
        <p className={clsx(classes.tableTitle, "fontSize")}>
          Additional Process
        </p>
        <div
          style={{
            maxWidth: "150mm",
            display: "flex",
            flexFlow: "column",
            alignItems: "flex-end",
            pageBreakInside: "avoid",
          }}
        >
          <TableLong
            className="fontSize"
            data={ADDITIONAL_DATA}
            columns={ADDITIONAL_COLUMNS}
            columnProps={ADDITIONAL_COL_PROPS}
            style={{ width: "100%" }}
          />
        </div>

        {printTarget !== "production" ? (
          <div
            style={{
              maxWidth: "150mm",
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-end",
              pageBreakInside: "avoid",
            }}
          >
            <TableHandling
              className="fontSize"
              itemName="additional"
              handling={data.additionalCostHandling}
              subtotalCost={additionalData.totalCost}
            />
          </div>
        ) : null}
      </div>
    )

    const outsourcingElement = (
      <div style={{ pageBreakInside: "avoid" }}>
        <p className={clsx(classes.tableTitle, "fontSize")}>
          Outsourcing Process
        </p>
        <div
          style={{
            maxWidth: "150mm",
            display: "flex",
            flexFlow: "column",
            alignItems: "flex-end",
            pageBreakInside: "avoid",
          }}
        >
          <TableLong
            className="fontSize"
            data={OUTSOURCING_DATA}
            columns={OUTSOURCING_COLUMNS}
            columnProps={ADDITIONAL_COL_PROPS}
            style={{ width: "100%" }}
          />
        </div>

        {printTarget !== "production" ? (
          <div
            style={{
              maxWidth: "150mm",
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-end",
              pageBreakInside: "avoid",
            }}
          >
            <TableHandling
              className="fontSize"
              itemName="outsourcing"
              handling={data.outsourcingCostHandling}
              subtotalCost={outsourcingData.totalCost}
            />
          </div>
        ) : null}
      </div>
    )

    /* Compute total cost */

    const rateInValue = data.rateInValue
    const rateOutValue = data.rateOutValue

    const totalProcess =
      processData.totalCost * (1 + num(data.processCostHandling) / 100)
    const totalMaterial =
      materialData.totalCost * (1 + num(data.materialCostHandling) / 100)
    const totalAdditional =
      additionalData.totalCost * (1 + num(data.additionalCostHandling) / 100)
    const totalOutsourcing =
      outsourcingData.totalCost * (1 + num(data.outsourcingCostHandling) / 100)
    const totalCost =
      totalProcess + totalMaterial + totalAdditional + totalOutsourcing

    const processPercentage = +((totalProcess / totalCost) * 100).toFixed(2)
    const materialPercentage = +((totalMaterial / totalCost) * 100).toFixed(2)
    const additionalPercentage = +((totalAdditional / totalCost) * 100).toFixed(
      2
    )
    const outsourcingPercentage = +(
      (totalOutsourcing / totalCost) *
      100
    ).toFixed(2)

    const totalCostsTable = (
      <table
        cellSpacing={0}
        className={clsx(classes.totalCostsTable, "fontSize")}
      >
        <thead>
          <tr>
            <th></th>
            <th>IDR</th>
            <th>{rateInCurr.code}</th>
            <th>{rateOutCurr.code}</th>
            <th>%</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Total Material Costs</th>
            <td>{numFormat(totalMaterial)}</td>
            <td>{numFormat(totalMaterial / rateInValue)}</td>
            <td>{numFormat(totalMaterial / rateOutValue)}</td>
            <td>
              {Number.isNaN(materialPercentage) ? 0 : materialPercentage}%
            </td>
          </tr>
          <tr>
            <th>Additional Costs</th>
            <td>{numFormat(totalAdditional)}</td>
            <td>{numFormat(totalAdditional / rateInValue)}</td>
            <td>{numFormat(totalAdditional / rateOutValue)}</td>
            <td>
              {Number.isNaN(additionalPercentage) ? 0 : additionalPercentage}%
            </td>
          </tr>
          <tr>
            <th>Outsourcing Costs</th>
            <td>{numFormat(totalOutsourcing)}</td>
            <td>{numFormat(totalOutsourcing / rateInValue)}</td>
            <td>{numFormat(totalOutsourcing / rateOutValue)}</td>
            <td>
              {Number.isNaN(outsourcingPercentage) ? 0 : outsourcingPercentage}%
            </td>
          </tr>
          <tr>
            <th>Total Process Costs</th>
            <td>{numFormat(totalProcess)}</td>
            <td>{numFormat(totalProcess / rateInValue)}</td>
            <td>{numFormat(totalProcess / rateOutValue)}</td>
            <td>{Number.isNaN(processPercentage) ? 0 : processPercentage}%</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <td>{numFormat(totalCost)}</td>
            <td>{numFormat(totalCost / rateInValue)}</td>
            <td>{numFormat(totalCost / rateOutValue)}</td>
            <td>
              {Number.isNaN(
                materialPercentage +
                  processPercentage +
                  additionalPercentage +
                  outsourcingPercentage
              )
                ? 0
                : materialPercentage +
                  processPercentage +
                  additionalPercentage +
                  outsourcingPercentage}
              %
            </td>
          </tr>
          <tr>
            <td style={{ paddingTop: "1rem", fontWeight: "bold" }}>MARGINS</td>
            {marginCurrencies.map((mc) => {
              return (
                <td
                  style={{ paddingTop: "1rem", fontWeight: "bold" }}
                  key={mc.code}
                >
                  {mc.code}
                </td>
              )
            })}
          </tr>
          {MARGINS.map((margin) => {
            return (
              <tr key={margin}>
                <td style={{ fontWeight: "bold" }}>{margin}%</td>
                {marginCurrencies.map((mc) => {
                  return (
                    <td>
                      {numFormat((1 + margin / 100) * (totalCost / mc.rate))}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tfoot>
      </table>
    )

    return (
      <div
        className={clsx(classes.page, className, "print-container")}
        ref={ref}
      >
        <table>
          <thead></thead>
          <tbody>
            <tr>
              <td>
                <HeaderPrint
                  companyInformation={companyInformation}
                  typeDocument="Internal"
                />
              </td>
            </tr>
            <tr>
              <td>
                <section className={clsx("chapter", classes.page)}>
                  <h1
                    className="title"
                    style={{ textTransform: "uppercase", fontSize: "14pt" }}
                  >
                    Estimation for Quotation
                  </h1>

                  <div style={{ display: "flex" }}>
                    <div className="left-side" style={{ flexGrow: 1 }}>
                      <table id="general" className="table-headleft fontSize">
                        <tbody>
                          <tr>
                            <th>To:</th>
                            <td>{customerData.name}</td>
                          </tr>
                          <tr>
                            <th>Attn:</th>
                            <td>{customerData.pic}</td>
                          </tr>
                          <tr>
                            <th>Product:</th>
                            <td>{data.productName}</td>
                          </tr>
                          <tr>
                            <th>Project Type:</th>
                            <td>{data.rfq?.processType?.processType || ""}</td>
                          </tr>
                          <tr>
                            <th>Product Type:</th>
                            <td>{data.rfq?.productType?.productType || ""}</td>
                          </tr>
                        </tbody>
                      </table>

                      <table id="lwt" className="table-headleft fontSize">
                        <tbody>
                          <tr>
                            <th>LWT1:</th>
                            <td>
                              {numFormatForLwt(data.length)} x{" "}
                              {numFormatForLwt(data.width)} x{" "}
                              {numFormatForLwt(data.height)}
                            </td>
                          </tr>
                          <tr>
                            <th>LWT2:</th>
                            <td>
                              {numFormatForLwt(data.length2)} x{" "}
                              {numFormatForLwt(data.width2)} x{" "}
                              {numFormatForLwt(data.height2)}
                            </td>
                          </tr>
                          <tr>
                            <th>LWT3:</th>
                            <td>
                              {numFormatForLwt(data.length3)} x{" "}
                              {numFormatForLwt(data.width3)} x{" "}
                              {numFormatForLwt(data.height3)}
                            </td>
                          </tr>
                          <tr>
                            <th>LWT4:</th>
                            <td>
                              {numFormatForLwt(data.length4)} x{" "}
                              {numFormatForLwt(data.width4)} x{" "}
                              {numFormatForLwt(data.height4)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div
                      className="right-side"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <table
                        id="lwt"
                        className="table-headleft fontSize"
                        style={{
                          marginBottom: "5mm",
                        }}
                      >
                        <tbody>
                          <tr>
                            <th>Estimation Number</th>
                            <td>{data.estimationNumber}</td>
                          </tr>
                          <tr>
                            <th>Estimation Date</th>
                            <td>{data.estimationDate}</td>
                          </tr>
                          <tr>
                            <th>Lead Time</th>
                            <td>{data.leadTime} work days</td>
                          </tr>
                        </tbody>
                      </table>

                      <div style={{ display: "flex" }} className="fontSize">
                        <p style={{ margin: "1mm 3mm" }}>Rate:</p>
                        <table id="rate" className="table-headleft fontSize">
                          <tbody>
                            <tr>
                              <th>{rateInCurr.code}</th>
                              <td>{data.rateInValue}</td>
                            </tr>
                            <tr>
                              <th>{rateOutCurr.code}</th>
                              <td>{data.rateOutValue}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {!["production"].includes(printTarget) ? (
                    <>
                      <div id="total-cost-table" style={{ display: "flex" }}>
                        {totalCostsTable}
                        {printTarget !== "customer" ? (
                          <div
                            style={{ position: "relative", marginLeft: "3mm" }}
                          >
                            <p>Approved margin</p>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className={classes.approvedBox}
                            >
                              <strong>{numFormat(margin)}</strong>
                            </div>
                            <p
                              style={{
                                position: "absolute",
                                left: "calc(100% + 4pt)",
                                top: "calc(25% - 7pt)",
                                fontSize: "13pt",
                              }}
                            >
                              %
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}

                  {approvalTable}

                  {materialData.items.length > 0 && materialElement}
                  {additionalData.items.length > 0 && additionalElement}
                  {outsourcingData.items.length > 0 && outsourcingElement}
                  {processData.items.length > 0 && processElement}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "2mm",
                      marginBottom: "3mm",
                      flexDirection: "column",
                      marginRight: "2em",
                    }}
                  >
                    <div
                      style={{ width: "100%", pageBreakInside: "avoid" }}
                      className="fontSizeComment"
                    >
                      <p style={{ fontWeight: "bold" }}>Comment:</p>
                      <div style={{ whiteSpace: "pre" }}>{data.comment}</div>
                    </div>
                  </div>
                </section>
              </td>
            </tr>
            <tr>
              <td>
                <p className="page-footer" style={{ fontSize: 12 }}>
                  {data?.rfq?.rfqNumber}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
)

export default EQPrintForm
